import React, { useState, useEffect, useRef } from 'react';
import './auction.css';
import MainFilter from '../../components/MainFilter/MainFilter';
import Filters from '../../components/Filters/Filters';
import Match from '../../components/Match/Match';

export default function Auction({ t, width, token, handleResponse }) {
  const countLoading = useRef(0);
  const [activeIndex, setActiveIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [first, setFirst] = useState(true);
  const [column, setColumn] = useState(true);
  const [filteredCars, setFilteredCars] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(
    {
      page: 1,
      status: activeIndex === 1 ? 'auction' : activeIndex === 0 ? 'preview' : activeIndex === 2 ? 'sold' : activeIndex === 3 ? 'simulcast' : 'dealerBlock',
      make: null,
      models: null,
      year_st: null,
      year_en: null,
      odometer_st: null,
      odometer_en: null,
    }
  );
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);

  useEffect(() => {
    if(countLoading.current == 0) {
      if (filter.make !== null) {
        fetchModels();
      }
      if (first) {
        fetchMakes();
      }
      fetchCars();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      countLoading.current++;
    } else {
      countLoading.current = 0;
    }
  }, [activeIndex, filter])

  const nameFilter = [
    t('upcomming'),
    t('active'),
    t('sold'),
    t('simulcast'),
    t('dealerBlock'),
  ];

  const fetchCars = async () => {
    const filterWithJsonModels = {
      ...filter,
      models: filter.models !== null ? JSON.stringify(filter.models) : null
    };

    const cleanedFilter = Object.fromEntries(
      Object.entries(filterWithJsonModels).filter(([_, value]) => value != null)
    );

    const queryString = new URLSearchParams(cleanedFilter).toString();
    const response = await fetch(process.env.REACT_APP_API_URL+`/vehicle-list?${queryString}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await handleResponse(response);
    if (response.ok) {
      console.log(data)
      if (filter.page === 1) {
        setFilteredCars(data);
      } else {
        setFilteredCars(prevCars => [...prevCars, ...data]);
      }
      if (first) {
        setFirst(false);
        setLoading(false);
      }
    }
  }

  const fetchMakes = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL+'/makes-list', {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await handleResponse(response);
    if (response.ok) {
      console.log(data)
      setMakes(data);
    }
  }

  const fetchModels = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL+'/models-list?make=' + filter.make, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await handleResponse(response);
    if (response.ok) {
      console.log(data)
      setModels(data);
    }
  }

  // const fetchCars = useCallback(async () => {
  //   const filterWithJsonModels = {
  //     ...filter,
  //     models: filter.models !== null ? JSON.stringify(filter.models) : null
  //   };
  
  //   const cleanedFilter = Object.fromEntries(
  //     Object.entries(filterWithJsonModels).filter(([_, value]) => value != null)
  //   );
  
  //   const queryString = new URLSearchParams(cleanedFilter).toString();
  //   const response = await fetch(`/api/vehicle-list?${queryString}`);
    
  //   if (response.ok) {
  //     const data = await response.json();
  //     setFilteredCars(data);
  //     setLoading(false);
  //   }
  // }, [filter]);
  
  // // Мемоизируем fetchMakes
  // const fetchMakes = useCallback(async () => {
  //   const response = await fetch('/api/makes-list');
  //   if (response.ok) {
  //     const data = await response.json();
  //     setMakes(data);
  //   }
  // }, []);
  
  // // Мемоизируем fetchModels
  // const fetchModels = useCallback(async () => {
  //   const response = await fetch('/api/models-list?make=' + filter.make);
  //   if (response.ok) {
  //     const data = await response.json();
  //     setModels(data);
  //   }
  // }, [filter.make]);
  
  // Объединённый useEffect
  // useEffect(() => {
  //   // Вызов fetchMakes только при монтировании
  //   fetchMakes();
  
  //   // При изменении фильтров и activeIndex
  //   setLoading(true);
    
  //   // Если make выбран, вызываем fetchModels
  //   if (filter.make !== null) {
  //     fetchModels();
  //   }
  
  //   // Всегда вызываем fetchCars
  //   fetchCars();
  
  // }, [fetchMakes, fetchModels, fetchCars, activeIndex, filter]);

  useEffect(() => {
    if (width >=1200) {
      setOpenFilter(true);
    }
  }, [width]);

  useEffect(() => {
    if (width >=1200) {
      setOpenFilter(true);
    }
  }, [width]);


  return (
    <div className='auction'>
      { width >= 1024 &&
        <div className="topContainer">
          <h1>{t('browse')}</h1>
          <p>{t('find')}</p>
        </div>
      }
      <MainFilter 
        activeIndex={activeIndex} 
        setActiveIndex={setActiveIndex} 
        column={column} 
        setColumn={setColumn} 
        setFilter={setFilter} 
        t={t} 
        width={width} 
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
      />
      <div className="centerContainer">
        <Filters
          filter={filter}
          setFilter={setFilter}
          makes={makes}
          models={models}
          t={t}
          width={width}
          openFilter={openFilter}
          token={token}
          handleResponse={handleResponse}
        />
        <Match nameFilter={nameFilter[activeIndex]} column={column} cars={filteredCars} loading={loading} t={t} width={width} setFilter={setFilter} />
      </div>
    </div>
  );
}
