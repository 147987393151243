import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../../assets/img/logo.svg'
import BrowseVehicles from '../../../buttons/BrowseVehicles/BrowseVehicles'
// import Notifications from '../../../buttons/Notifications/Notifications'
import Whatchlist from '../../../buttons/Whatchlist/Whatchlist'
import Profilebtn from '../../../buttons/Profilebtn/Profilebtn'
import SwitchLanguage from '../../../buttons/SwitchLanguage/SwitchLanguage'
import LogInBtn from '../../../buttons/LogInBtn/LogInBtn'
import SignUpBtn from '../../../buttons/SignUpBtn/SignUpBtn'
import login from '../../../assets/img/login.svg'
import './header.css'

export default function Header({ t, i18n, textBtn, userLoggedIn, setLogin, setToken, token, handleResponse }) {
  const location = useLocation();
  const showButtonsAuth = location.pathname === '/';
  const [isClicked, setIsClicked] = useState(false);
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {

    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL+'/user', {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const data = await handleResponse(response);
      if (response.ok) {
        setName(data.name);
        localStorage.setItem('user', JSON.stringify(data));
        // console.log(data);
      }
    }

    console.log(userLoggedIn);

    if (token) {
      fetchData();
    }

  }, [userLoggedIn]);

  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });


  const handleClick = () => {
    setTimeout(() => {
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 600); // Убираем тень через 150 мс
    }, 150); // Задержка 600 мс перед добавлением тени
  };
  return (
    <div className='header'>
      <div className="logoContainer">
        <Link to='/' className='logo'>
          <img src={logo} alt="sunmax" />
        </Link>
        <Link to='https://t.me/Export_Canada' target="_blank" className='tgLink'>
          <Telegram />
        </Link>
      </div>
      {userLoggedIn ?
        <>
          <div className='centerButtons'>
            <BrowseVehicles t={t} />
          </div>
          <div className='endButtons'>
            {/* <Notifications t={t} /> */}
            <Whatchlist t={t} />
            <Profilebtn t={t} i18n={i18n} setLogin={setLogin} name={name} setToken={setToken} token={token} handleResponse={handleResponse} />
          </div>
        </>
        :
        <div className="headerButtons">
          <SwitchLanguage t={t} i18n={i18n} />
          {showButtonsAuth && (
            window.innerWidth <= 1024 ?
              <div ref={containerRef}>
                <button className='loginSvg' onClick={() => setOpen(!open)} ref={containerRef}>
                  <img src={login} alt="login" />
                </button>
                {open &&
                  <div>
                    <svg className="loginMenuSvg" width="20" height="10" viewBox="0 0 20 10" xmlns="http://www.w3.org/2000/svg">
                      <polygon points="10,0 20,10 0,10" fill="white" />
                    </svg>
                    <div className='loginMenu'>
                      <LogInBtn t={t} />
                      {/* <SignUpBtn t={t} textBtn={textBtn} onClick={handleClick} isClicked={isClicked} /> */}
                    </div>
                  </div>
                }
              </div>
              :
              <>
                <LogInBtn t={t} />
                <SignUpBtn t={t} textBtn={textBtn} onClick={handleClick} isClicked={isClicked} styleHeader={{ padding: '8px 14px' }} />
              </>
          )}
        </div>
      }
    </div>
  )
}


function Telegram() {
  return (
    <svg width="29px" height="29px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="14" fill="#0061FF" />
      <path d="M22.9866 10.2088C23.1112 9.40332 22.3454 8.76755 21.6292 9.082L7.36482 15.3448C6.85123 15.5703 6.8888 16.3483 7.42147 16.5179L10.3631 17.4547C10.9246 17.6335 11.5325 17.541 12.0228 17.2023L18.655 12.6203C18.855 12.4821 19.073 12.7665 18.9021 12.9426L14.1281 17.8646C13.665 18.3421 13.7569 19.1512 14.314 19.5005L19.659 22.8523C20.2585 23.2282 21.0297 22.8506 21.1418 22.1261L22.9866 10.2088Z" fill="white" />
    </svg>
  );
}
