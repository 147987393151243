import React, { useState, useEffect } from 'react';
import './filters.css';
import CustomSelect from '../CustomSelect/CustomSelect';
import RangeFilter from '../RangeFilter/RangeFilter';
import SaveFilters from '../SaveFilters/SaveFilters';

export default function Filters({ filter, setFilter, makes, models, t, width, openFilter, token, handleResponse }) {
    const [clickedClear, setClickedClear] = useState(false);
    const [searchValueMakes, setSearchValueMakes] = useState('');
    const [searchValueModels, setSearchValueModels] = useState('');
    const [isOpenMakes, setIsOpenMakes] = useState(false);
    const [isOpenModels, setIsOpenModels] = useState(false);
    const [userFilters, setUserFilters] = useState([]);
    const [currentFilter, setCurrentFilter] = useState(null);

    useEffect(() => { fetchFilters() }, []);

    const fetchFilters = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL+'/filters', {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        const data = await handleResponse(response);
        if (response.ok) {
            setUserFilters(data);
            console.log(data)
        }
    }

    const handleClearFilters = () => {
        setTimeout(() => {
            setClickedClear(true);
            setTimeout(() => {
                setClickedClear(false);
            }, 600);
        }, 0);
        setCurrentFilter(null)
        if (filter.make !== null ||
            filter.models !== null ||
            filter.year_st !== null ||
            filter.year_en !== null ||
            filter.odometer_st !== null ||
            filter.odometer_en !== null) {

            setFilter(prevFilter => ({
                ...prevFilter,
                make: null,
                models: null,
                year_st: null,
                year_en: null,
                odometer_st: null,
                odometer_en: null,
            }));
        }
    };

    return (
        openFilter &&
        <div className='filterContainer'>
            {width >= 1200 &&
                <h2>{t('filters')}</h2>
            }

            <div className="rowButtons">
                <CustomSelect
                    nameFilter={'Makes'}
                    setIsOpen={setIsOpenMakes}
                    isOpen={isOpenMakes}
                    setSearchValue={setSearchValueMakes}
                    searchValue={searchValueMakes}
                    options={makes}
                    filter={filter}
                    setFilter={setFilter}
                    t={t}
                    width={width}
                    setCurrentFilter={setCurrentFilter}
                />
                <CustomSelect
                    nameFilter={'Models'}
                    setIsOpen={setIsOpenModels}
                    isOpen={isOpenModels}
                    setSearchValue={setSearchValueModels}
                    searchValue={searchValueModels}
                    options={models}
                    filter={filter}
                    setFilter={setFilter}
                    t={t}
                    width={width}
                    setCurrentFilter={setCurrentFilter}
                />
            </div>
            <RangeFilter
                label='Year'
                unit=""
                filter={filter}
                setFilter={setFilter}
                t={t}
                width={width}
                setCurrentFilter={setCurrentFilter}
            />
            <RangeFilter
                label='Odometer'
                unit="km"
                filter={filter}
                setFilter={setFilter}
                t={t}
                width={width}
                setCurrentFilter={setCurrentFilter}
            />
            <SaveFilters
                userFilters={userFilters}
                filter={filter}
                setFilter={setFilter}
                fetchFilters={fetchFilters}
                t={t}
                width={width}
                currentFilter={currentFilter}
                setCurrentFilter={setCurrentFilter}
                token={token}
                handleResponse={handleResponse}
            />
            <div className="interaction">
                <button className={`clearbtn ${clickedClear ? 'clicked' : ''}`} onClick={handleClearFilters}>{t('clear')}</button>
                {/* <button className={`applybtn ${clickedApply ? 'clicked' : ''}`} onClick={handleApplyFilters}>Apply</button> */}
            </div>
        </div>
    );
}
