import React from 'react'
import './footer.css'

export default function Footer({ t }) {
  return (
    <div className='footer'>
      <p>{t('rights')}</p>
      <div className='contactsContainer'>
        <a href="tel:7787082030">778-708-2030</a>
        <a href="mailto:info@sunmaxmotors.ca">info@sunmaxmotors.ca</a>
      </div>
      <div className='addressContainer'>
        <p>Sunmax Motors</p>
        <p>104-19415 56 Ave.</p>
        <p>Surrey, BC, V3S6K2</p>
      </div>
      {/* <p>Sunmax Motors 104-19415 56 Ave. Surrey, BC, V3S6K2</p> */}
    </div>
  )
}
