import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SignUpBtn from '../../buttons/SignUpBtn/SignUpBtn';
import '../Registration/registration.css';
import './login.css'

const Login = ({ t, textBtn, setLogin, setToken }) => {
    const navigate = useNavigate();
    const [isClicked, setIsClicked] = useState(false);
    const [errorMessage, setErrorMessage] =useState('')

    useEffect(() => {
        // const fetchData = async () => {
        //     await fetch(process.env.REACT_APP_API_URL+'/parser');
        // }
        //
        // fetchData();

        const fetchCSRFData = async () => {
            await fetch(process.env.REACT_APP_API_URL+'/csrf-cookie', {
                method: 'GET',
                credentials: 'include'
            });
        }

        //fetchCSRFData();

        const contentElement = document.querySelector('.content');
        if (contentElement) {
            contentElement.style.display = 'flex';
            contentElement.style.alignItems = 'center';
            contentElement.style.justifyContent = 'center';
        }
        return () => {
            if (contentElement) {
                contentElement.style.display = '';
                contentElement.style.alignItems = '';
                contentElement.style.justifyContent = '';
            }
        };

    }, []);

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const [errors, setErrors] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setErrorMessage('')
        // Убираем ошибку при фокусе на поле
        if (errors[e.target.name]) {
            setErrors({
                ...errors,
                [e.target.name]: ''
            });
        }
    };

    const validate = () => {
        let errors = {};
        if (!formData.email) {
            errors.email = t('error2');
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = t('error3');;
        }
        if (!formData.password) {
            errors.password = t('error6');
        } else {
            let passwordErrors = [];
            if (formData.password.length < 8) {
                passwordErrors.push(t('error7'));
            }
            if (!/[A-Z]/.test(formData.password)) {
                passwordErrors.push(t('error8'));
            }
            if (!/[^A-Za-z]/.test(formData.password)) {
                passwordErrors.push(t('error9'));
            }
            if (passwordErrors.length > 0) {
                errors.password = passwordErrors.join(', ');
            }
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
        setTimeout(() => {
            setIsClicked(true);
            setTimeout(() => {
                setIsClicked(false);
            }, 600); // Убираем тень через 600 мс
        }, 150);
        if (Object.keys(validationErrors).length === 0) {
            console.log('Форма отправлена:', formData);
            const response = await fetch(process.env.REACT_APP_API_URL+'/auth', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(formData)
            })
            if (response.ok) {
                setLogin(true);
                const data = await response.json();
                localStorage.setItem('email', formData.email);
                localStorage.setItem('token', data.token);
                //localStorage.setItem('user', JSON.stringify(data.user));
                //setToken(data.token);
                navigate('/auction');
                return
            }

            const data = await response.json();
            if (data.error === 'No user') {
                setErrorMessage(t('error4'))
            } else if (data.error === 'No approve') {
                setErrorMessage(t('error5'))
            }
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <form className='formAuth' onSubmit={handleSubmit}>
            <div className="formName">
                <h2>{t('Log in')}</h2>
                <p>{t('logintext')}</p>
            </div>
            <div className='formItem' style={{ position: 'relative' }}>
                <label>{t('email')} <span className='danger'>*</span></label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="example@mail.com"
                    maxLength={56}
                    style={{
                        borderColor: errors.email ? 'red' : '',
                    }}
                    autoFocus
                />
                {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className='formItem' style={{ position: 'relative' }}>
                <label>{t('pass')} <span className='danger'>*</span></label>
                <input
                    type={passwordVisible ? 'text' : 'password'}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder={t('enter_pass')}
                    maxLength={56}
                    style={{
                        borderColor: errors.password ? 'red' : '',
                    }}
                />
                {errors.password && <p className="error">{errors.password}</p>}
                <span onClick={togglePasswordVisibility} style={{ position: 'absolute', right: 10, top: 44, cursor: 'pointer' }}>
                <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={passwordVisible ? 'strikethrough' : ''}
                        style={{ position: 'relative' }}
                    >
                        <path d="M7.99995 11.3867C6.40661 11.3867 5.11328 10.0933 5.11328 8.50001C5.11328 6.90668 6.40661 5.61334 7.99995 5.61334C9.59328 5.61334 10.8866 6.90668 10.8866 8.50001C10.8866 10.0933 9.59328 11.3867 7.99995 11.3867ZM7.99995 6.61334C6.95995 6.61334 6.11328 7.46001 6.11328 8.50001C6.11328 9.54001 6.95995 10.3867 7.99995 10.3867C9.03995 10.3867 9.88661 9.54001 9.88661 8.50001C9.88661 7.46001 9.03995 6.61334 7.99995 6.61334Z" fill="#292D32"/>
                        <path d="M8.00022 14.5134C5.49355 14.5134 3.12688 13.0467 1.50021 10.5C0.793548 9.40003 0.793548 7.60669 1.50021 6.50003C3.13355 3.95336 5.50022 2.48669 8.00022 2.48669C10.5002 2.48669 12.8669 3.95336 14.4935 6.50003C15.2002 7.60003 15.2002 9.39336 14.4935 10.5C12.8669 13.0467 10.5002 14.5134 8.00022 14.5134ZM8.00022 3.48669C5.84688 3.48669 3.78688 4.78003 2.34688 7.04003C1.84688 7.82003 1.84688 9.18003 2.34688 9.96003C3.78688 12.22 5.84688 13.5134 8.00022 13.5134C10.1535 13.5134 12.2135 12.22 13.6535 9.96003C14.1535 9.18003 14.1535 7.82003 13.6535 7.04003C12.2135 4.78003 10.1535 3.48669 8.00022 3.48669Z" fill="#292D32"/>
                        <line
                            x1="2"
                            y1="15"
                            x2="14"
                            y2="2"
                            stroke="#292D32"
                            strokeWidth="1"
                            strokeLinecap="round"
                            className="drawLine"
                            style={{ opacity: passwordVisible ? '100%' : '0' }}
                        />
                    </svg>
                </span>
            </div>
            <SignUpBtn
                type="submit"
                isClicked={isClicked}
                onClick={handleSubmit}
                t={t}
                textBtn={textBtn}
                style={{
                    width: '100%',
                }}
            />
            {errorMessage ?
            <p className='errorMessage'>
                 {errorMessage} 
            </p>
            : ''}
            <div className="formDown">
                <div className="neverReg">
                <p>{t('never')}</p>
                <Link to="/registration">
                    {t('now')}
                </Link>
                </div>
                <Link to="recovery">
                    {t('forgot')}
                </Link>
            </div>

        </form>
    );
};

export default Login;
