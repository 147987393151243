import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './profile.css'

export default function Profile({ t, token, handleResponse }) {
    const [info, setInfo] = useState(1);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState(false);
    const containerRef = useRef(null);

    const userInfo = JSON.parse(localStorage.getItem('user'));

    function switchPage(index) {
        setInfo(index);
    }

    const forgotPassword = async () => {
        setEmail(true);
        const response = await fetch(process.env.REACT_APP_API_URL+'/forgot-password?email=' + localStorage.getItem('email'), {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        handleResponse(response);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (containerRef.current && !containerRef.current.contains(event.target)) {
            setOpen(!open);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      });
    return (
        <div className='profile'>
            <Link to='/auction' >
                <button className='backPage'>
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="#0D6efd" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_167_21481)">
                            <path d="M2.63423 0.364572C2.90099 0.0918141 3.33349 0.0918141 3.60025 0.364572C3.86701 0.637331 3.86701 1.07956 3.60025 1.35232L2.20481 2.77914H11.3142C11.6914 2.77914 11.9973 3.09184 11.9973 3.47758C11.9973 3.86332 11.6914 4.17602 11.3142 4.17602H2.18051L3.60025 5.62769C3.86701 5.90045 3.86701 6.34267 3.60025 6.61543C3.33349 6.8882 2.90099 6.8882 2.63423 6.61543L0.302056 4.23082C-0.0980819 3.82167 -0.0980806 3.15833 0.302056 2.74919L2.63423 0.364572Z" />
                        </g>
                    </svg>
                    <p>{t('browse')}</p>
                </button>
            </Link>
            <div className="profileText">
                <h2>{t('account')}</h2>
                <p>{t('manage')}</p>
            </div>
            <div className="switchInfo">
                <div className="switchInfoContainer">
                    <button className={info === 1 ? 'active' : ''} onClick={() => switchPage(1)}>
                        {t('personal')}
                    </button>
                    <button className={info === 2 ? 'active' : ''} onClick={() => switchPage(2)}>
                        {t('security')}
                    </button>
                </div>
            </div>
            <div className="mainInfo">
                <div className="general">
                    <h2>{info === 1 ? t('personal') : t('login')}</h2>
                    <div className="personal">
                        <h3>{info === 1 ? t('name') : t('username')}</h3>
                        <p>{info === 1 ? userInfo.name : userInfo.email}</p>
                    </div>
                    <hr />
                    <div className="underBlock">
                        <div className="personal">
                            <h3>{info === 1 ? t('email_addres') : t('pass')}</h3>
                            <p>{info === 1 ? userInfo.email : '***'}</p>
                        </div>
                        {(info === 2) && !email &&
                            <Link onClick={forgotPassword}>
                                {t('reset')}
                            </Link>
                        }
                        {(info === 2) && email && 
                            <Link>
                                {t('reset_conf')}
                            </Link>
                        }
                    </div>
                </div>
                <div className="clue">
                    {info === 1 ?
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.0003 21.25C14.717 21.25 10.417 16.95 10.417 11.6667C10.417 6.38334 14.717 2.08334 20.0003 2.08334C25.2837 2.08334 29.5837 6.38334 29.5837 11.6667C29.5837 16.95 25.2837 21.25 20.0003 21.25ZM20.0003 4.58334C16.1003 4.58334 12.917 7.76668 12.917 11.6667C12.917 15.5667 16.1003 18.75 20.0003 18.75C23.9003 18.75 27.0837 15.5667 27.0837 11.6667C27.0837 7.76668 23.9003 4.58334 20.0003 4.58334Z" fill="#0061FF" />
                            <path d="M26.3666 37.9167C25.7333 37.9167 25.1333 37.6833 24.6999 37.2499C24.1833 36.7333 23.9499 35.9833 24.0666 35.2L24.3833 32.95C24.4666 32.3667 24.8166 31.6833 25.2333 31.25L31.1332 25.35C33.4999 22.9834 35.5833 24.3334 36.6 25.35C37.4666 26.2167 37.9166 27.1499 37.9166 28.0833C37.9166 29.0333 37.4833 29.9166 36.6 30.8L30.6999 36.7C30.2832 37.1167 29.5833 37.4667 29 37.55L26.7499 37.8666C26.6166 37.8999 26.4999 37.9167 26.3666 37.9167ZM33.8499 26.5333C33.5499 26.5333 33.2832 26.7334 32.8999 27.1167L26.9999 33.0167C26.9499 33.0667 26.8666 33.2333 26.8666 33.3L26.5666 35.3833L28.6499 35.0833C28.7166 35.0666 28.8832 34.9834 28.9332 34.9334L34.8333 29.0333C35.1 28.7666 35.4166 28.3833 35.4166 28.0833C35.4166 27.8333 35.2166 27.4834 34.8333 27.1167C34.4333 26.7167 34.1333 26.5333 33.8499 26.5333Z" fill="#0061FF" />
                            <path d="M34.8667 32.0333C34.75 32.0333 34.6333 32.0167 34.5333 31.9833C32.3333 31.3667 30.5833 29.6167 29.9667 27.4167C29.7833 26.75 30.1667 26.0667 30.8334 25.8834C31.5 25.7001 32.1833 26.0833 32.3667 26.75C32.75 28.1166 33.8334 29.2 35.2 29.5833C35.8667 29.7667 36.2501 30.4666 36.0667 31.1166C35.9167 31.6666 35.4167 32.0333 34.8667 32.0333Z" fill="#0061FF" />
                            <path d="M5.68359 37.9167C5.00026 37.9167 4.43359 37.35 4.43359 36.6667C4.43359 29.55 11.417 23.75 20.0003 23.75C21.817 23.75 23.617 24.0167 25.3003 24.5167C25.967 24.7167 26.3336 25.4167 26.1336 26.0667C25.9336 26.7334 25.2336 27.1 24.5836 26.9C23.117 26.4667 21.5836 26.2333 20.0003 26.2333C12.8003 26.2333 6.93359 30.9 6.93359 36.65C6.93359 37.35 6.36693 37.9167 5.68359 37.9167Z" fill="#0061FF" />
                        </svg>
                        :
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.0003 37.9334C18.1837 37.9334 16.3837 37.4 14.967 36.35L7.80033 31C5.90033 29.5834 4.41699 26.6334 4.41699 24.2667V11.8667C4.41699 9.30002 6.30033 6.56669 8.71699 5.66669L17.0337 2.55002C18.6837 1.93336 21.2837 1.93336 22.9337 2.55002L31.267 5.66669C33.6837 6.56669 35.567 9.30002 35.567 11.8667V24.25C35.567 26.6167 34.0837 29.5667 32.1837 30.9834L25.017 36.3334C23.617 37.4 21.817 37.9334 20.0003 37.9334ZM17.917 4.90002L9.60033 8.01669C8.16699 8.55002 6.91699 10.35 6.91699 11.8834V24.2667C6.91699 25.85 8.03366 28.0667 9.28366 29L16.4503 34.35C18.367 35.7834 21.617 35.7834 23.5337 34.35L30.7003 29C31.967 28.05 33.067 25.8334 33.067 24.2667V11.8667C33.067 10.35 31.817 8.55002 30.3837 8.00002L22.067 4.88336C20.967 4.48336 19.0337 4.48336 17.917 4.90002Z" fill="#0061FF" />
                            <path d="M20.0003 22.0833C17.467 22.0833 15.417 20.0333 15.417 17.5C15.417 14.9666 17.467 12.9166 20.0003 12.9166C22.5337 12.9166 24.5837 14.9666 24.5837 17.5C24.5837 20.0333 22.5337 22.0833 20.0003 22.0833ZM20.0003 15.4166C18.8503 15.4166 17.917 16.35 17.917 17.5C17.917 18.65 18.8503 19.5833 20.0003 19.5833C21.1503 19.5833 22.0837 18.65 22.0837 17.5C22.0837 16.35 21.1503 15.4166 20.0003 15.4166Z" fill="#0061FF" />
                            <path d="M20 27.0834C19.3167 27.0834 18.75 26.5167 18.75 25.8334V20.8334C18.75 20.15 19.3167 19.5834 20 19.5834C20.6833 19.5834 21.25 20.15 21.25 20.8334V25.8334C21.25 26.5167 20.6833 27.0834 20 27.0834Z" fill="#0061FF" />
                        </svg>
                    }
                    <h3>{info === 1 ? t('need') : t('secure')}</h3>
                    {info === 2 && <p>{t('keep')}</p>}
                    <p>{t('to_update')} <button onClick={() => setOpen(!open)}>{t('support')}</button></p>
                    {open &&
                        <div className="contacts">
                            <div className="contactsBlock" ref={containerRef}>
                                <a href="mailto:info@sunmaxmotors.ca">info@sunmaxmotors.ca</a>
                                <a href="https://t.me/AR6P70">@AR6P70</a>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
