import React, { useState } from 'react';
import './saveFilters.css';
import { Icon } from '@iconify/react';

export default function SaveFilters({ userFilters, filter, setFilter, fetchFilters, t, currentFilter, setCurrentFilter, token, handleResponse }) {
    const [isClicked, setIsClicked] = useState(false);
    const [filterName, setFilterName] = useState('')

    const handleInputChange = (e) => {
        setFilterName(e.target.value);
    };

    const handleSaveFilter = async () => {
        if ((filter.make !== null ||
            filter.models !== null ||
            filter.year_st !== null ||
            filter.year_en !== null ||
            filter.odometer_st !== null ||
            filter.odometer_en !== null) && filterName !== '') {
            setTimeout(() => {
                setIsClicked(true);
                setTimeout(() => {
                    setIsClicked(false);
                }, 600); // Убираем тень через 600 мс
            }, 0);

            const filterData = {
                ...filter,
                name: filterName, // Добавление filterName в объект
            };

            const response = await fetch(process.env.REACT_APP_API_URL+'/filters', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(filterData)
            })
            handleResponse(response);
            if (response.ok) {
                setFilterName('');
                fetchFilters();
            }
        }
        console.log(filter)
    };

    const handleDeleteFilter = async (id) => {
        const response = await fetch(process.env.REACT_APP_API_URL+'/filters?filter_id=' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        if (response.ok) {
            fetchFilters();
        }
    };

    const handleApplyFilter = (filter) => {
        console.log(filter.odometr_st)
        setFilter(prevFilter => ({
            ...prevFilter,
            make: filter.make,
            models: !filter.models || filter.models.length === 0 ? null : filter.models,
            year_st: filter.year_st,
            year_en: filter.year_en,
            odometer_st: filter.odometer_st,
            odometer_en: filter.odometer_en,
        }));
        setCurrentFilter(filter);
    };

    const isActiveFilter = (filter) => {
        return currentFilter && 
           filter.make === currentFilter.make && 
           filter.models === currentFilter.models &&
           filter.year_st === currentFilter.year_st &&
           filter.year_en === currentFilter.year_en;
    };

    return (
        <div className='saveFilters'>
            <input 
                type="text"
                id='save'
                value={filterName}
                onChange={handleInputChange}
                placeholder={t('enter')}
            />
            <button className={`saveItem ${isClicked ? 'clicked' : ''}`} onClick={handleSaveFilter}>
                {t('save')}
            </button>
            <div className="savedItems">
                {userFilters.map(filter => (
                    <div 
                        key={filter.id} 
                        className={`item ${isActiveFilter(filter) ? 'activeFilter' : ''}`} 
                    >
                        <div className="check" onClick={() => handleApplyFilter(filter)}>
                            <input 
                                type="checkbox" 
                                id={filter.id} 
                                className='itemCheckbox' 
                                checked={isActiveFilter(filter)}
                            />
                            <p>{filter.name}</p>
                        </div>
                        <Icon
                            icon="ph:trash"
                            width="20px" height="20px"
                            className='trash'
                            onClick={() => handleDeleteFilter(filter.id)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
